const globalResources = {
    api: 'api',
    agreement: 'agreements',
    cdr: 'cdrs',
    connector: 'connectors',
    dashboard: 'dashboard',
    evse: 'evses',
    interop: 'interop',
    invoice: 'invoices',
    creditNote: 'credit-notes',
    invoiceLine: 'invoice-lines',
    location: 'locations',
    logout: 'logout', // /logout
    network: 'networks',
    previewEmail: 'preview-emails',
    refund: 'refunds',
    session: 'sessions',
    setting: 'settings',
    tariff: 'tariffs',
    translation: 'translations',
    alert: 'alert',
    user: 'users',
}

const zestResources = {
    appSynchroChecker: 'apps-synchronization-checker',
    chargeSessionTester: 'charge-session-tester',
    supervisor: 'supervisors',
    contact: 'contacts',
    contactGroup: 'contacts/groups',
    device: 'devices',
    fix: 'fix',
    fixHistory: 'history',
    fixStats: 'fix/stats',
    fixTags: 'tags',
    issue: 'issues',
    maintenanceAlert: 'maintenance-alerts',
    message: 'messages',
    task: 'tasks',
    lan: 'lans',
    retrocessionsPdfGenerator: 'retrocessions-pdf-generator',
    stat: 'stats',
    sticker: 'evse-stickers-generator',
    upload: 'uploads',
    retrocession: 'retrocession-groups',
    retrocessionGroupLog: 'retrocession-group-logs',
    report: 'station-check',
    tag: 'tags',
}

const pulpResources = {
    call: 'calls',
    contract: 'contracts',
    feedback: 'feedback',
    importTokens: 'import-activable-tokens',
    merchandise: 'merchandises',
    order: 'orders',
    provider: 'providers',
    rating: 'ratings',
    subscription: 'subscriptions',
    token: 'tokens',
    transaction: 'transactions',
    driver: 'drivers',
    vehicles: 'vehicles',
    fleet: 'fleet',
    billingCenter: 'billing-centers',
    balanceRefund: 'balance-refund',
    roamingTariff: 'roaming-tariff-configs',
    receipt: 'receipts',
    payment: 'payments',
    sellerCompanies: 'seller-companies',
    countries: 'countries',
}

export const resources = {
    ...globalResources,
    ...zestResources,
    ...pulpResources,
}

export const actionNamespaces = {
    // Namespaces of action param
    // Could be usefull if we change a redodant namespace someday (ex: "activity" > "audit")
    audit: 'audit',
    business: 'business',
    logs: 'logs',
    configuration: 'configurations',
    curve: 'curve', // session curves
    detail: 'details', // sidebar home
    ocpi: 'ocpi',
    ocpp: 'ocpp',
    map: 'map',
    dashboard: 'dashboard',
    fix: {
        preventive: 'preventive',
        corrective: 'corrective',
        information: 'information',
        history: 'history',
    },
}

export class Url {
    constructor (resource, param, action, ...additionalParams) {
        this.resources = resources
        this.actions = actionNamespaces

        // reset by children
        this.additionalParams = additionalParams // empty array if not defined

        this.resource = this.resources[resource]
        this.action = action
            ? this.actions[action] || action
            : null // set namespace definition if defined
        this.param = param
        this.baseUrl = ''
        if (resource && !this.resource) throw new Error(`Resource "${resource}" is invalid (need to be singular), check "resources" keys`)

        this.setBaseUrl()
    }

    get value () {
        return this.baseUrl
    }

    // Call by children or if the parent class is used directly
    setBaseUrl () {
        let baseUrl = `/${this.resource}`

        if (this.param) baseUrl += `/${this.param}`

        if (this.action) baseUrl += `/${this.action}`

        if (this.param === 'csv') {
            // Special case for CSV urls
            baseUrl = `/${this.resource}/export/${this.param}`
        }

        if (this.additionalParams.length) {
            // Adding all additional params to the url
            // eslint-disable-next-line no-return-assign
            this.additionalParams.map((param) => baseUrl += `/${param}`)
        }

        this.baseUrl = baseUrl
    }

    // Adding multiple params to the base url
    // Ex: used for csv urls that need a "from" and "to" params additionaly
    setParams (...params) {
        if (!this.baseUrl) this.setBaseUrl()

        // eslint-disable-next-line no-return-assign
        params.map((param) => this.baseUrl += `/${param}`)
        return this
    }

    // Special nested url in Zest
    setZestAgreementUrl (networkRef, agreementRef = null, action = null) {
        let baseUrl = `/${resources.network}/${networkRef}/${resources.agreement}`

        if (agreementRef) baseUrl += `/${agreementRef}`

        if (action) baseUrl += `/${action}`

        return baseUrl
    }

    // Special nested url in Zest
    setZestLanUrl (locationRef, lanId = null, action = null) {
        let baseUrl = `/${resources.location}/${locationRef}/${resources.lan}`

        if (lanId) baseUrl += `/${lanId}`

        if (action) baseUrl += `/${action}`

        return baseUrl
    }

    /**
     * @param {string} param Name of the param
     * @param {*} value Convert to string for url
     * @returns {Object}
     */
    setQueryString (param, value) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl) + param}=${value}`
        return this
    }

    /**
     * @param {String} resource base url resource (evse, user ....)
     * @param {*} value Convert to string for url
     * Used in rare cases when urls are complicated (eg: lan url in Zest)
     * @returns {string}
     */
    setShortcutUrl (resource, value) {
        return `/${resources[resource]}/shortcut/${value}`
    }

    // ----
    // Methods to add a defined query string to url (used by both platforms)
    byConnector (connectorRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}connector=${connectorRef}`
        return this
    }

    byEvse (evseRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}evse=${evseRef}`
        return this
    }

    byLocation (locationRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}location=${locationRef}`
        return this
    }

    byBillingCenter (billingCenterId) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}billingCenter=${billingCenterId}`
        return this
    }

    forVehicle (value = false) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}isForVehicle=${value}`
        return this
    }

    byDriver (driverId) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}driver=${driverId}`
        return this
    }

    byNetwork (networkRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}network=${networkRef}`
        return this
    }

    byParent (parentRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}parent=${parentRef}`
        return this
    }

    byProvider (providerRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}provider=${providerRef}`
        return this
    }

    byStatus (statusName) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}status=${statusName}`
        return this
    }

    byTab (tabName) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}tab=${tabName}`
        return this
    }

    byUser (userRef) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}enduser=${userRef}`
        return this
    }

    byToken (uid) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}token=${uid}`
        return this
    }

    bySubscription (emaid) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}subscription=${emaid}`
        return this
    }

    byDevice (cbi) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}device=${cbi}`
        return this
    }

    byEnduserGroup (enduser_group) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}enduserGroup=${enduser_group}`
    }

    byMerchandise (merchandises) {
        merchandises.forEach((merchandise) => {
            this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}merchandises[]=${merchandise}`
        })
    }

    byRetrocessionGroup (id) {
        this.baseUrl += `${this.setQueryStringOperator(this.baseUrl)}retrocession_group=${id}`
        return this
    }

    // This method determines whether the url has a query string or not
    // If the url has a query string, it returns an ampersand
    // If the url does not have a query string, it returns a question mark
    setQueryStringOperator (url) {
        const hasQueryString = /\?.+=.*/g.test(url)
        return hasQueryString
            ? '&'
            : '?'
    }
}
